import React, { Component } from "react";

class Esophagealmanometry extends Component {
    render() {
      return (
        <>
            <h4>Esophageal Manometry</h4>

            <div className="video-container">
            <iframe width="853" title="esophagealmanometry video" height="480" src="https://www.youtube.com/embed/DZ5uz7JAyJ8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <p>This procedure involves passing a narrow, soft tube through the nostril or mouth and into the esophagus.  This tube measures pressures in different areas of the esophagus so that your doctor can evaluate how strongly the esophagus muscle contracts when you swallow and how competent the sphincter (valve) is at the end of the esophagus.  In most cases, this is an easily tolerated procedure.  You may feel a little pressure in your nostril and slight gagging as the tube is initially passed.  These sensations subside once the tube is positioned and your body becomes adjusted to the tube.  This study takes about an hour to perform.  There is no sedation given, so you will be able to drive and go to work after the procedure is completed.</p>

            <h5>Preparation</h5>

            <p>You will not be able to eat or drink anything for six hours prior to the test.  The following drugs should be stopped for at least 48 hours:  Reglan (metoclopramide) and Urecholine (bethanechol).  You may wish to consult your physician first.  Some heart and blood pressure medicines may affect the test; you physician should review these and decide if any need to be held before the test.</p>
        </>
      );
    }
  }
   
  export default Esophagealmanometry;