import React, { Component } from "react";

class ScreeningColonoscopy extends Component {
    render() {
      return (
        <>
        <p align="center"><font size="5"><strong>Screening Colonoscopy</strong></font></p>

      <h4>Who:</h4>

      <p>Anyone over the age of 45 - you are considered at average risk
      for developing colon cancer</p>
          <ul>
              <li>Anyone with one of the following conditions - you are
          considered at elevated risk for developing colon cancer:</li>
              <ul>
                  <li>Inflammatory bowel disease, particularly ulcerative colitis of greater than 7 years duration</li>
                  <li>Personal history of colon cancer or polyps</li>
                  <li>Family history of colon cancer or polyps</li>
                  <li>Certain familial polyposis or cancer syndromes</li>
              </ul>
          </ul>

      <h4>Why:</h4>

      <p>Colon cancer is the most common cancer in the United States.
      It is the second most common cause of cancer death in the United
      States (after lung cancer). If discovered early, however, colon
      cancer is also one of the most curable of malignancies. Most
      colon cancers tend to start out as colon polyps, or benign
      growths, in the lining of the colon (large intestine). If one of
      these polyps (or growths) is discovered early and removed, it can
      never develop into a colon cancer. The efficacy of screening
      colonoscopy to prevent colon cancer in the general population has
      been amply demonstrated in multiple medical studies.</p>

      <h4>What:</h4>

      <p>Screening colonoscopy is a procedure where the colon is
      cleansed, then, after appropriate sedation is administered, an
      instrument called a colonoscope is inserted into the rectum. This
      instrument has a video camera at the tip, and is inserted to a
      distance adequate to view the entire colon. If any small growths
      or polyps are found, they are typically removed during the
      colonoscopy. Colonoscopy can also diagnose other conditions,
      including inflammatory bowel disease, diverticulosis, and
      hemorrhoids.</p>

      <h4>When:</h4>

      <p>The American Cancer Society recommends screening colonoscopy
      for all adults over the age of 50, and every 10 years thereafter
      if the colonoscopy is normal. If you fall into a high risk
      category, then earlier screening colonoscopy may be warranted. If
      an abnormality is found, more frequent surveillance colonoscopy
      may be indicated (typically every 3 to 5 years if a polyp is found).</p>

      <h4>Where:</h4>

      <p>Colonoscopy is performed in a licensed endoscopy center,
      either hospital-based or free standing. Most colonoscopies are
      now performed in free-standing endoscopy centers; when and where
      your colonoscopy are performed will be determined after your
      pre-screening visit with your gastroenterologist.</p>

      <h4>Insurance Coverage</h4>

      <p>
      Pennsylvania legislation requires all insurers doing business
      in the Commonwealth of Pennsylvania to provide coverage for
      screening colonoscopy.</p>

      <p>Insurers do cover screening colonoscopy at 100
      percent, but diagnostic colonoscopy may be covered at a lesser percent (typically 80 to 100
      percent, depending upon the individual plan).&nbsp; Our office will do its best
      to help you remain informed about your own insurance plan.</p>

      <p>On July 1, 2001 Medicare recognized the value of screening
      colonoscopy for the prevention of colon cancer, and now provides
      payment for screening colonoscopy.&nbsp;</p>

      <p>&nbsp;</p>
        </>
      );
    }
  }
   
  export default ScreeningColonoscopy;