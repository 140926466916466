import React, { Component } from "react";

class Directions extends Component {
    render() {
      return (
        <>
        <h3>Directions</h3>

            <a className="linkOnPage" href="https://www.google.com/maps/place/1421+Fishburn+Rd,+Hershey,+PA+17033/@40.2635221,-76.643866,17z/data=!3m1!4b1!4m5!3m4!1s0x89c8bb554e9f3b0d:0x915a9051a0a0bff6!8m2!3d40.263518!4d-76.6416773" target="_blank" rel="noopener noreferrer">1421 Fishburn Road, Hershey, PA 17033</a>


            <p>&nbsp;</p>

            <p><b>The office is located on the corner of Bachmanville Road and
            Fishburn Road (Route 743) in Hershey; it is 1/2 mile south of the Giant
            Supermarket at the corner of Fishburn and Cocoa Avenue. Turn onto Bachmanville
            Road to enter the parking lot.</b></p>

            <p>&nbsp;</p>

            <p><strong>From Locations West of Hershey (Harrisburg)</strong></p>

            <p><u>Take Route 322 East</u> toward Hershey. When you see the
            Hershey Medical Center, continue straight (the main road becomes Route 422). Continue on Route
            422 (West Chocolate Avenue) until you reach
            the second traffic light (CVS Drugstore is on the corner); turn
            right onto Hockersville Road. Continue on Hockersville Road, straight
            through the traffic light (Hockersville Road becomes Fishburn Road), and
            continue straight for 1 mile.&nbsp; The office is on the left, at the corner of Fishburn
            and Bachmanville Roads; take the left onto Bachmanville Road; and the first left
            into the parking lot.</p>

            <p>&nbsp;</p>

            <p><strong>From Locations East of Hershey (Palmyra, Lebanon)</strong></p>

            <p><u>Route 322</u>: Stay on Route 322 West until you reach Homestead Road
            (Hershey High School is on the corner). Turn left onto Homestead Road. Take the first right
            (0.6 miles) onto Bachmanville Road; and the
            first right (0.3 miles) into the parking lot.</p>

            <p><u>Route 422</u>: Stay on Route 422 West until you reach Homestead Road
            (across from the Chocolate Factory entrance).&nbsp; Turn left onto Homestead
            Road, go 0.9 miles (Hershey High School is on the corner). Continue straight
            through the traffic light on Homestead Road. Take the first right (0.6 miles) onto
            Bachmanville Road; and the
            first right (0.3 miles) into the parking lot.</p>

            <p><u>Route 743</u>: The office is located on the corner of Route 743 and
            Bachmanville Road, about 1 1/2 miles north of the Derry Township Line (about 1/2
            mile south of the Giant Supermarket).&nbsp; Turn right onto Bachmanville Road,
            and enter the parking lot on your left.</p>

            <p>&nbsp;</p>

            <p><strong>From Locations North of Hershey</strong></p>

            <p><u>Route 39 South (South Hershey Road):</u>&nbsp; Take a left at 
            the traffic light at the intersection with HersheyPark Drive onto HersheyPark
            Drive.&nbsp; Then turn right on Route 743 and follow the directions in
            the following paragraph.&nbsp;</p>

            <p><u>Route 743 South</u>: Follow the signs and stay on Route 743 South.&nbsp;
            When you reach the Giant Supermarket, Route 743 turns to the left; remain on
            Route 743 for another 1/2 mile.&nbsp; The office is on the left, at the corner
            of Fishburn and Bachmanville Roads; take the left onto Bachmanville Road; and the first
            left into the parking lot.</p>



        </>
      );
    }
  }
   
  export default Directions;